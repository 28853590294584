(function ($) {
  $(document).on('locator.quebecDetect', function (e, isQB) {
    if (isQB) {
      var $mhb = $('.js-mobile-help__buttons');

      if ($mhb.hasClass('grid-container--2')) {
        $mhb.removeClass('grid-container--2').addClass('grid-container--1 altered-by-footer_top_formatter_v1_js');
      }
    }
  });
})(jQuery);
